import './App.css';
import Nav from './component/Nav';
import Body from './component/Body';
import LoginDialog from './component/LoginDialog';
import LogoutDialog from './component/LogoutDialog';
import SignupDialog from './component/SignupDialog';
import ConfirmationDialog from './component/ConfirmationDialog';
import react from 'react';
import bootstrap from 'bootstrap/dist/js/bootstrap.min.js'

class App extends react.Component {
    
  constructor(props) {
      super(props);
      this.body = react.createRef();
  }

  handleSignupClick = () => {
      console.log("handleSignupClick");
      this.signupModal = new bootstrap.Modal(document.getElementById('signupModal'));
      this.signupModal.show();
  }
  handleLoginClick = () => {
      console.log("handleLoginClick");
      this.loginModal = new bootstrap.Modal(document.getElementById('loginModal'));
      this.loginModal.show();
  }
  handleLogoutClick = () => {
      console.log("handleLogoutClick");
      this.logoutModal = new bootstrap.Modal(document.getElementById('logoutModal'));
      this.logoutModal.show();
  }
  handleLoginSuccess = () => {
      console.log("handleLoginSuccess");
      this.loginModal.hide();
      this.notifyLoginListeners();
      this.forceUpdate();
  }
  handleSignupSuccess = () => {
      console.log("handleSignupSuccess");
      this.signupModal.hide();
      this.confirmationModal = new bootstrap.Modal(document.getElementById('confirmationModal'));
      this.confirmationModal.show();
  }
  handleConfirmationSuccess = () => {
      console.log("handleConfirmationSuccess");
      this.confirmationModal.hide();
      this.notifyLoginListeners();
      this.forceUpdate();
  }
  handleLogoutSuccess = () => {
      console.log("handleLogoutSuccess");
      this.logoutModal.hide();
      this.notifyLoginListeners();
      this.forceUpdate();
  }
  loginListeners = [];
  addLoginListener = (loginListener) => {
      this.loginListeners.push(loginListener);
  }
  removeLoginListener = (loginListener) => {
      this.loginListeners = this.loginListeners.filter(item => item !== loginListener);
  }
  notifyLoginListeners(){
      for (const element of this.loginListeners) {
          element.loginStatusChanged();
      }
  }
  render() {
      return (
          <div>
              <Nav
                  onSignupClick={() => this.handleSignupClick()}
                  onLoginClick={() => this.handleLoginClick()}
                  onLogoutClick={() => this.handleLogoutClick()}
              />
              <Body 
                  ref={this.body} 
                  addLoginListener={(loginListener) => this.addLoginListener (loginListener)}
              />
              <LoginDialog onSuccess={() => this.handleLoginSuccess()} />
              <SignupDialog onSuccess={() => this.handleSignupSuccess()} />
              <LogoutDialog onSuccess={() => this.handleLogoutSuccess()} />
              <ConfirmationDialog onSuccess={() => this.handleConfirmationSuccess()} />
          </div>
      );
  }
}

export default App;

