
import React from 'react';
import UserService from '../service/UserService'
import bootstrap from 'bootstrap/dist/js/bootstrap.min.js'
import ProblemsGrid from './ProblemsGrid'
import jsPDF from "jspdf";
import AdditionParamsDialog from './AdditionParamsDialog';
import SubtractionParamsDialog from './SubtractionParamsDialog';
import MultiplicationParamsDialog from './MultiplicationParamsDialog';
import DivisionParamsDialog from './DivisionParamsDialog';

function Toast(props) {
    return (
        <div id="alertToast" className="toast position-absolute end-0 hide" role="alert" aria-live="assertive" aria-atomic="true" >
            <div className="toast-header">
                <strong className="me-auto">{props.toastHeader}</strong>
                <button type="button" className="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
            </div>
            <div className="toast-body">
                {props.toastMessage}
            </div>
        </div>
    );
}

class Body extends React.Component {
    constructor(props) {
        super(props);
        this.problemsGridRef = React.createRef();
        this.state = {
            numCols: 4,
            numRows: 8,
            type:"",
        }
    }

    componentDidMount() {
        this.props.addLoginListener({
            loginStatusChanged: this.loginStatusChanged
        });
        // this.loginStatusChanged();
    }

    loginStatusChanged = () => {
        if (UserService.isUserLoggedIn()) {
            this.problemsGridRef.current.displayList();
            this.toastMessage = "Welcome, " + UserService.getLoggedInUserName();
            this.toastHeader = "Login Success";
        }
        else {
            this.toastMessage = "We will miss you.";
            this.toastHeader = "Logout success";
        }
        var toast = bootstrap.Toast.getOrCreateInstance(document.getElementById('alertToast'));
        if (toast) {
            toast.show();
        }
    }

    downloadProblems = () => {
        var sideMargin = 10;
        const doc = new jsPDF();
        var problemsElement = document.getElementById('problems');
        var elementWidth = problemsElement.offsetWidth;
        var pageWidth = doc.internal.pageSize.getWidth() - 2 * sideMargin;
        var scale = pageWidth / elementWidth;
        var elementheight = problemsElement.offsetHeight;
        var pageHeight = doc.internal.pageSize.getHeight();
        var topBottomMargin = (pageHeight - elementheight * scale) / 2;
        doc.html(document.getElementById('problems'), {
            callback: function (doc) {
              doc.save("problems.pdf");
            },
            html2canvas:{scale: scale},
            margin: [topBottomMargin, topBottomMargin, sideMargin, sideMargin]
        });
    }

    render() {
        return (
            <div className="container-fluid">
                <Toast toastMessage={this.toastMessage} toastHeader={this.toastHeader} />
                <div className="row">
                    <div className="col">
                        <div className="row">
                            <div className="col-2">
                            Number of Columns:
                                <select className="form-select" id="numCols" name="numCols" placeholder="numCols" value={this.state.numCols} onChange={(e) => this.handleChange(e)} >
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="6">6</option>
                                </select>
                            </div>
                            <div className="col-2">
                                Number of Rows:
                                <select className="form-select" id="numRows" name="numRows" placeholder="numRows" value={this.state.numRows} onChange={(e) => this.handleChange(e)} >
                                    <option value="4">4</option>
                                    <option value="6">6</option>
                                    <option value="8">8</option>
                                </select>
                            </div>
                            <div className="col-2">
                                <button type="button" className="btn btn-primary" onClick={() => this.addition()}>Addition</button>
                            </div>
                            <div className="col-2">
                                <button type="button" className="btn btn-primary" onClick={() => this.subtraction()}>Subtraction</button>
                            </div>
                            <div className="col-2">
                                <button type="button" className="btn btn-primary" onClick={() => this.multiplication()}>Multiplication</button>
                            </div>
                            <div className="col-2">
                                <button type="button" className="btn btn-primary" onClick={() => this.division()}>Division</button>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <button type="button" className="btn btn-primary" onClick={() => this.downloadProblems()}>Download PDF</button>
                    </div>
                </div>
                <div id="problems" className="row">
                    <ProblemsGrid items={this.state.items} ImplementationComponent={this.state.type} numCols={this.state.numCols} numRows={this.state.numRows} itemClicked={(itemData) => {}} ref={this.problemsGridRef} />
                </div>
                <AdditionParamsDialog numCols={this.state.numCols} numRows={this.state.numRows} onSuccess={(items, type) => this.handleParamsSuccess(items, type)} />
                <SubtractionParamsDialog numCols={this.state.numCols} numRows={this.state.numRows} onSuccess={(items, type) => this.handleParamsSuccess(items, type)} />
                <MultiplicationParamsDialog numCols={this.state.numCols} numRows={this.state.numRows} onSuccess={(items, type) => this.handleParamsSuccess(items, type)} />
                <DivisionParamsDialog numCols={this.state.numCols} numRows={this.state.numRows} onSuccess={(items, type) => this.handleParamsSuccess(items, type)} />
            </div>
        );
    }

    handleChange(event) {
        this.setState({[event.target.name] : event.target.value});
    }

    addition = () => { 
        console.log("handleAdditionClick");
        this.paramsModal = new bootstrap.Modal(document.getElementById('additionModal'));
        this.paramsModal.show();
    }
    subtraction = () => { 
        console.log("handleSubtractionClick");
        this.paramsModal = new bootstrap.Modal(document.getElementById('subtractionModal'));
        this.paramsModal.show();
    }
    multiplication = () => { 
        console.log("handleMultiplicationClick");
        this.paramsModal = new bootstrap.Modal(document.getElementById('multiplicationModal'));
        this.paramsModal.show();
    }
    division = () => { 
        console.log("handleDivisionClick");
        this.paramsModal = new bootstrap.Modal(document.getElementById('divisionModal'));
        this.paramsModal.show();
    }
    handleParamsSuccess = (items, type) => { 
        console.log("handleParamsSuccess");
        this.setState({type: type});
        this.setState({items: items});
        this.paramsModal.hide();
    }
  }

export default Body;
