class ProblemsService {

    getRandomIntUnder = (max) => {
        return Math.floor(Math.random() * max);
    }

    getRandomInt = (numDigits) => {
        var min = Math.ceil(10 ** (numDigits - 1));
        var max = Math.floor(10 ** (numDigits) - 1);
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    generate2OrderedParams = (numDigits) => {
        var firstParam = this.getRandomInt(numDigits);
        var secondParam = this.getRandomInt(numDigits);
        if (secondParam > firstParam){
            var temp = firstParam;
            firstParam = secondParam;
            secondParam = temp;
        }
        return {
            first: firstParam,
            second: secondParam
        };
    }

    getRightDigit = (num, digit) => {
        const numStr = String(num);
        if(numStr.length <= digit){
            return 0;
        }
        const digitStr = numStr[numStr.length - digit - 1];
        return Number(digitStr);
    }

    additionWithCarryover = (first, second, countCarryoverDigits = 0, carryoverDigits = [], carryoverDigitsAvoid = []) => {
        var firstDigit;
        var secondDigit;
        var carryoverDigitsCount = 0;
        for(var i = 0; i < String(first).length; i++){
            firstDigit = this.getRightDigit(first, i);
            secondDigit = this.getRightDigit(second, i)
            var carryoverDigit = (firstDigit + secondDigit >= 10);
            if(carryoverDigit){
                carryoverDigitsCount++;
                if(carryoverDigitsAvoid.includes(i)){
                    return false;
                }
            }
            else{
                if(carryoverDigits.includes(i)){
                    return false;
                }
            }
        }
        if(countCarryoverDigits !== carryoverDigitsCount) {
            return false;
        }
        return true;
    }

    generateCarryoverAdditionProblem = (index, numDigits = 2, maxResult = 2 * (10 ** numDigits), countCarryoverDigits = 1, carryoverDigits = [], carryoverDigitsAvoid = []) => {
        var params = this.generate2OrderedParams(numDigits);
        while((params.first + params.second > maxResult) || 
                (!this.additionWithCarryover(params.first, params.second, countCarryoverDigits, carryoverDigits, carryoverDigitsAvoid))){
            params = this.generate2OrderedParams(numDigits);
        }
        return {
            id: index,
            firstParam: params.first,
            secondParam: params.second,
            operation: "+",
            result: params.first + params.second
        };
    }

    subtractionWithBorrow = (first, second, countBorrowDigits = 0, borrowDigits = [], borrowDigitsAvoid = []) => {
        var firstDigit;
        var secondDigit;
        var borrowDigitsCount = 0;
        for(var i = 0; i < String(first).length; i++){
            firstDigit = this.getRightDigit(first, i);
            secondDigit = this.getRightDigit(second, i)
            var borrowDigit = (firstDigit - secondDigit < 0);
            if(borrowDigit){
                borrowDigitsCount++;
                if(borrowDigitsAvoid.includes(i)){
                    return false;
                }
            }
            else{
                if(borrowDigits.includes(i)){
                    return false;
                }
            }
        }
        if(countBorrowDigits !== borrowDigitsCount) {
            return false;
        }
        return true;
    }

    generateBorrowSubtractionProblem = (index, numDigits = 2, minResult = 2 * (10 ** numDigits), countBorrowDigits = 1, borrowDigits = [], borrowDigitsAvoid = []) => {
        var params = this.generate2OrderedParams(numDigits);
        while((params.first - params.second < minResult) || 
                (!this.subtractionWithBorrow(params.first, params.second, countBorrowDigits, borrowDigits, borrowDigitsAvoid))){
            params = this.generate2OrderedParams(numDigits);
        }
        return {
            id: index,
            firstParam: params.first,
            secondParam: params.second,
            operation: "-",
            result: params.first - params.second
        };
    }

    generateMultiplicationProblem = (index, numDigits = 2, maxResult = (10 ** numDigits ** 2), minResult = 0) => {
        var params = this.generate2OrderedParams(numDigits);
        while((params.first * params.second < minResult) && (params.first * params.second > maxResult)){
            params = this.generate2OrderedParams(numDigits);
        }
        return {
            id: index,
            firstParam: params.first,
            secondParam: params.second,
            operation: "X",
            result: params.first * params.second
        };
    }

    generateDivisionProblem = (index, numDigits = 2, maxResult = (10 ** numDigits ** 2), minResult = 0, allowRemainder = false) => {
        var params = this.generate2OrderedParams(numDigits);
        while((params.first * params.second < minResult) && (params.first * params.second > maxResult)){
            params = this.generate2OrderedParams(numDigits);
        }
        var remainder = 0;
        if(allowRemainder){
            remainder = this.getRandomIntUnder(params.first);
        }
        return {
            id: index,
            firstParam: params.second * params.first + remainder,
            secondParam: params.first,
            operation: "÷",
            result: params.second + "\nr: " + remainder
        };
    }

}

export default new ProblemsService()
