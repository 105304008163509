
import React from 'react';
import classnames from 'classnames';

class ProblemsGrid extends React.Component {
    constructor(props) {
        super(props);
        this.selectedItem = null;
        this.children = new Map();
    }

    createRow(i){
        var internalList = [];
        var smallMode = (this.props.numCols > 4);
        for (var j = 0; j < this.props.numCols; j++){
            if(this.props.items && this.props.items.length > i * this.props.numCols + j){
                const ImplementationComponent = this.props.ImplementationComponent;
                var item = this.props.items[i * this.props.numCols + j];
                var childRef = React.createRef();
                internalList.push(<div key={j} className={classnames("col-" + 12 / this.props.numCols, "d-flex", "justify-content-center")}><ImplementationComponent smallMode={smallMode} key={item.id} ref={childRef} data={item} itemClicked={(itemData) => {this.selectedItem = itemData; this.props.itemClicked(itemData)}}/></div>);
                this.children.set(item.id, childRef);
            }
        }
        return <div key={i} className='row'>{internalList}</div>
    }

    createList(){
        var list = [];
        this.children = new Map();
        for (var i = 0; i < this.props.numRows; i++){
            list.push(this.createRow(i));
        }
        return list;
    }
    
    render() {
        return (
            <div className="row">
                {this.createList()}
            </div>
        );
    }

}

export default ProblemsGrid;
