import React from 'react';
import UserService from '../service/UserService'

class LoginDialog extends React.Component {

    constructor() {
        super();
        this.state = {
            username: 'admin@math.fileme.info',
            password: '',
            showErrorMessage: false
        };
    }

    render() {
        let errorMessage = <div/>;
        if(this.state.showErrorMessage){
            errorMessage = <div className="alert alert-danger" role="alert">Login Error! Please try again!</div>
        }
        return (
            <div className="modal fade" id="loginModal" tabIndex="-1" role="dialog" aria-labelledby="loginModalTitle" aria-hidden="true">
                <form name="loginForm" method="post" action="">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="loginModalTitle">Login</h5>
                                <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                {errorMessage}
                                <input type="email" className="form-control" id="username" name="username" placeholder="email" value={this.state.username} onChange={(e) => this.handleChange(e)} required />
                                <input type="password" className="form-control" id="loginPassword" name="password" placeholder="password" value={this.state.password} onChange={(e) => this.handleChange(e)} required />
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                <button type="button" className="btn btn-primary" onClick={() => this.loginClicked()}>Login</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        );
    }
    loginClicked() {
        this.setState({ showErrorMessage: false });
        UserService
            .executeJwtAuthentication(this.state.username, this.state.password)
            .then((response) => {
                console.log("Login Success");
                UserService.registerSuccessfulLoginForJwt(this.state.username, response.data.token.token);
                this.props.onSuccess();
            }).catch(() => {
                console.log("Login Failed");
                this.setState({ showErrorMessage: true });
            });
    }
    handleChange(event) {
        this.setState({[event.target.name] : event.target.value});
    }
}

export default LoginDialog;
