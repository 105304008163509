import React from 'react';
import UserService from '../service/UserService'

class SignupDialog extends React.Component {

    constructor() {
        super();
        this.state = {
            name:'',
            surname:'',
            email: '',
            password: '',
            showErrorMessage: false
        };
    }

    render() {
        let errorMessage = <div/>;
        if(this.state.showErrorMessage){
            errorMessage = <div className="alert alert-danger" role="alert">Sign Up Error! Please try again!</div>
        }
        return (
            <div className="modal fade" id="signupModal" tabIndex="-1" role="dialog" aria-labelledby="signupModalTitle" aria-hidden="true">
                <form name="signupForm" method="post" action="">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="loginModalTitle">Sign Up</h5>
                                <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                {errorMessage}
                                <input type="text" className="form-control" id="name" name="name" placeholder="name" value={this.state.name} onChange={(e) => this.handleChange(e)} required />
                                <input type="text" className="form-control" id="surname" name="surname" placeholder="surname" value={this.state.surname} onChange={(e) => this.handleChange(e)} required />
                                <input type="email" className="form-control" id="email" name="email" placeholder="email" value={this.state.email} onChange={(e) => this.handleChange(e)} required />
                                <input type="password" className="form-control" id="signupPassword" name="password" placeholder="password" value={this.state.password} onChange={(e) => this.handleChange(e)} required />
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                <button type="button" className="btn btn-primary" onClick={() => this.signupClicked()}>Sign Up</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        );
    }
    signupClicked() {
        UserService
            .createUser(this.state.name, this.state.surname, this.state.email, this.state.password)
            .then((response) => {
                console.log("UserCreated");
                this.props.onSuccess();
            }).catch(() => {
                console.log("Signup Failed");
                this.setState({ showErrorMessage: true });
            });
    }
    handleChange(event) {
        this.setState({[event.target.name] : event.target.value});
    }
}

export default SignupDialog;
