
import React from 'react';
import classnames from 'classnames';

class Problem extends React.Component {
    render() {
        var widthClass = "w-25";
        if(this.props.smallMode){
            widthClass = "w-50";
        }
        return (
            <div className={classnames(widthClass)}>
                <div className="row">
                    <div className="col-3">
                        <h3 className="align-bottom">{this.props.data.operation}</h3>
                    </div>
                    <div className="col-9">
                        <div className="row text-end">
                            <h3>{this.props.data.firstParam}</h3>
                        </div>
                        <div className="row text-end">
                            <h3>{this.props.data.secondParam}</h3>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <hr/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                    </div>
                </div>
            </div>
        );
    }

}

export default Problem;
