
import React from 'react';
import UserService from '../service/UserService'

function LoggedInMenu(props) {
    return (
        <form className="d-flex">
            <div className="nav-link text-white">Welcome, {UserService.getLoggedInUserName()}</div>
            <button type="button" className="btn btn-link"  onClick={() => props.onLogoutClick()} >Logout</button>;
        </form>
    );
}
  
function LoggedOutMenu(props) {
    return (
        <form className="d-flex">
            <button type="button" className="btn btn-link" onClick={() => props.onSignupClick()} >Sign Up</button>
            <button type="button" className="btn btn-link" onClick={() => props.onLoginClick()} >Login</button>
        </form>
    );
  }

class Nav extends React.Component {
    render() {
        let menu;
        if (UserService.isUserLoggedIn()) {
            menu = <LoggedInMenu onLogoutClick={this.props.onLogoutClick} />;
        } else {
            menu = <LoggedOutMenu onSignupClick={this.props.onSignupClick} onLoginClick={this.props.onLoginClick} />;
        }
        return (
        <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
            <div className="container-fluid">
                <a className="navbar-brand" href="/">
                    <div className="row">
                        <div className="col-sm align-self-center">
                            <img src="/math.png" width="80px" height="80px" alt="" loading="lazy"></img>
                        </div>
                        <div className="col-sm align-self-center">
                            <p className="font-weight-bold mb-0">Math</p>
                        </div>
                    </div>
                </a>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarScroll" aria-controls="navbarScroll" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarScroll">
                    <ul className="navbar-nav me-auto my-2 my-lg-0 navbar-nav-scroll">
                        <li className="nav-item">
                            <a className="nav-link active" aria-current="page" href="/">Home</a>
                        </li>
                    </ul>
                    {menu}
                </div>
            </div>
        </nav>
        );
    }
}

export default Nav;
