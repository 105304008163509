
import React from 'react';
import UserService from '../service/UserService'

class LogoutDialog extends React.Component {

    render() {
        return (
            <div className="modal fade" id="logoutModal" tabIndex="-1" role="dialog" aria-labelledby="logoutModalTitle" aria-hidden="true">
                <form name="logoutForm" method="post" action="">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="logoutModalTitle">Logout</h5>
                                <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <p>Are you sure you want to logout?</p>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                <button type="button" className="btn btn-primary" onClick={() => this.logoutClicked()}>Logout</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        );
    }
    logoutClicked() {
        UserService.logout();
        this.props.onSuccess();
    }
}

export default LogoutDialog;
