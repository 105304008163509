import React from 'react';
import ProblemsService from "../service/ProblemsService"
import Problem from "./Problem"

class SubtractionParamsDialog extends React.Component {

    constructor() {
        super();
        this.state = {
            numDigits: 2, 
            minResult: 0, 
            countBorrowDigits: 1, 
            borrowDigits: [], 
            borrowDigitsAvoid: [],
            showErrorMessage: false
        };
    }

    render() {
        let errorMessage = <div/>;
        if(this.state.showErrorMessage){
            errorMessage = <div className="alert alert-danger" role="alert">Generation Error! Please try again!</div>
        }
        return (
            <div className="modal fade" id="subtractionModal" tabIndex="-1" role="dialog" aria-labelledby="subtractionModalTitle" aria-hidden="true">
                <form name="subtractionForm" method="post" action="">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="subtractionModalTitle">Subtraction Params</h5>
                                <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                {errorMessage}
                                <div className="mb-3">
                                    <label htmlFor="numDigits" className="col-form-label">Number of Digits:</label>
                                    <input type="number" min="1" max="8" className="form-control" id="numDigits" name="numDigits" placeholder="Num Digits" value={this.state.numDigits} onChange={(e) => {this.handleChange(e)}} required />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="minResult" className="col-form-label">Min Result:</label>
                                    <input type="number" min="10" max="200000000" className="form-control" id="minResult" name="minResult" placeholder="Max Result" value={this.state.minResult} onChange={(e) => this.handleChange(e)} required />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="countBorrowDigits" className="col-form-label">Number of Borrow Digits:</label>
                                    <input type="number" min="0" max="7" className="form-control" id="countBorrowDigits" name="countBorrowDigits" placeholder="Borrow Digits Count" value={this.state.countBorrowDigits} onChange={(e) => this.handleChange(e)} required />
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                <button type="button" className="btn btn-primary" onClick={() => this.generationClicked()}>Generate Problems</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        );
    }
    generationClicked() {
        this.setState({ showErrorMessage: false });
        var items = [];
        for (var i = 0; i < this.props.numCols * this.props.numRows ; i++){
            items.push(ProblemsService.generateBorrowSubtractionProblem(i, + this.state.numDigits, + this.state.minResult, + this.state.countBorrowDigits));
        }
        this.props.onSuccess(items, Problem);
    }
    handleChange(event) {
        this.setState({[event.target.name] : event.target.value});
    }
}

export default SubtractionParamsDialog;
