import React from 'react';
import UserService from '../service/UserService'

class ConfirmationDialog extends React.Component {

    constructor() {
        super();
        this.state = {
            token: ''
        };
    }

    render() {
        return (
            <div className="modal fade" id="confirmationModal" tabIndex="-1" role="dialog" aria-labelledby="confirmationModalTitle" aria-hidden="true">
                <form name="confirmationForm" method="post" action="">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="confirmationModalTitle">Confirm Token</h5>
                                <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <input type="email" className="form-control" id="token" name="token" placeholder="token" value={this.state.token} onChange={(e) => this.handleChange(e)} required />
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                <button type="button" className="btn btn-primary" onClick={() => this.confirmClicked()}>Confirm</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        );
    }
    confirmClicked() {
        UserService
            .confirmUser(this.state.token)
            .then((response) => {
                console.log("Confirmation Success");
                UserService.registerSuccessfulLoginForJwt(response.data.user.email, response.data.token.token);
                this.props.onSuccess();
            }).catch(() => {
                console.log("Confirmation Failed");
            });
    }
    handleChange(event) {
        this.setState(
            {
                [event.target.name] : event.target.value
            }
        );
    }
}

export default ConfirmationDialog;
