import axios from 'axios'
import config from '../config.json';

export const USER_NAME_SESSION_ATTRIBUTE_NAME = 'authenticatedUser'

class UserService {

    createUser(name, surname, email, password) {
        console.log(email);
        return axios.post(config.apiUrl + "/user", {
            name,
            surname,
            email,
            password
        });
    }

    confirmUser(token) {
        var bodyFormData = new FormData();
        bodyFormData.append('token', token);
        return axios({
            method: "post",
            url: config.apiUrl + "/user/confirm",
            data: bodyFormData,
            headers: { "Content-Type": "multipart/form-data" },
          });
    }

    executeJwtAuthentication(username, password) {
        console.log(username);
        return axios.post(config.apiUrl + "/authenticate", {
            username,
            password
        })
    }

    registerSuccessfulLoginForJwt(username, token) {
        sessionStorage.setItem(USER_NAME_SESSION_ATTRIBUTE_NAME, username)
        this.setupAxiosInterceptors(this.createJWTToken(token))
    }

    createJWTToken(token) {
        return 'Bearer ' + token
    }

    logout() {
        sessionStorage.removeItem(USER_NAME_SESSION_ATTRIBUTE_NAME);
    }

    isUserLoggedIn() {
        let user = sessionStorage.getItem(USER_NAME_SESSION_ATTRIBUTE_NAME)
        if (user === null) return false
        return true
    }

    getLoggedInUserName() {
        let user = sessionStorage.getItem(USER_NAME_SESSION_ATTRIBUTE_NAME)
        if (user === null) return ''
        return user
    }

    setupAxiosInterceptors(token) {
        axios.interceptors.request.use(
            (config) => {
                if (this.isUserLoggedIn()) {
                    config.headers.authorization = token
                }
                return config
            }
        )
    }
}

export default new UserService()